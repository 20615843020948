import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css';
import {Button} from 'vant';
const app = createApp(App);
app.use(Button)
createApp(App).use(store).use(vant).use(router).mount('#app')

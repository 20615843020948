import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/page",
  },
  {
    path: "/page",
    name: "page",
    component: () => import("../views/page/indexA.vue"),
    children: [
      {
        path: "/page",
        redirect: "/page/home",
      },
      {
        path: "home",
        name: "home",
        component: () => import("../views/page/home/indexA.vue"),
      },
      {
        path: "one",
        name: "one",
        component: () => import("../views/page/one/indexA.vue"),
      },
      {
        path: "two",
        name: "two",
        component: () => import("../views/page/two/indexA.vue"),
      },
      {
        path: "three",
        name: "three",
        component: () => import("../views/page/three/indexA.vue"),
      },
    ],
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/detail/indexA.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search/indexA.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  
  <router-view/>
</template>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

</style>
